<template>
    <div>
    <Breadcrumbs main="" title="Dashboard" />
    <div style="background-color: rgb(230 243 254);border-radius: 30px;">
      <b-row class="p-3 p-md-5" style="margin-bottom: -100px !important">
        <b-col>
          <b-card >
              <b-row style="display: flex; align-items: center;">
                  <b-col class="col-md-3 col-12">
                  <b-form-group label="Date Range">
                 
                    <date-range-picker
                              style="display: inline;"
                              ref="picker"
                              opens="right"
                              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                              :singleDatePicker="false"
                              :timePicker="false"
                              :timePicker24Hour="false"
                              :showWeekNumbers="false"
                              :showDropdowns="true"
                              :autoApply="true"
                              v-model="dateRange"
                              @update="ChangedDate()"
                              :linkedCalendars="false"
                              :ranges="customDateRanges"
                              :max-date="new Date()"
                    >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        <span style="font-size: 80%;"> {{ changeDateFormate(dateRange.startDate) }} - {{ changeDateFormate(dateRange.endDate) }}</span> 
                      </template>
                    </date-range-picker> 
                  </b-form-group>
                </b-col>

                <b-col>
                      <b-button @click="filterData" variant="primary">Filter</b-button>
                      <b-button class="ml-2" @click="init()" variant="primary">Reset</b-button>
                </b-col>
              </b-row>
          </b-card>
        </b-col>
      </b-row>


        <b-row class="p-3 p-md-5">
            <b-col lg="12">
                <content-page></content-page>
            </b-col>
            <b-col lg="12" class="mt-2">
            <px-card title="In-App">
            <b-row class="p-3" style="max-height: 60vh;overflow-y: scroll;">
             
              <b-card
                style="
                  border-radius: 10px;
                  max-width: 15rem;
                  border: 1px dashed #1d75bc;
                "
                v-for="item in bannerList"
                :key="item._id"
                type="gradient-red"
                class="ml-3 mt-1"
              >
                <div>
                  <div style="min-height: 80px;max-height: 80px;overflow: auto;">
                    <h4 class="text-blue">{{  item.spdocs.name +" - " +item.sdocs.title }}</h4>
                  </div>
                  <b-badge variant="dark">{{adTypeArray.find(e=> e.id == item.sdocs.adtype).name }}</b-badge>
                  <div>
                    <span style="font-size: 10px" class="m-0 text-blue">{{
                      new Date(item.createdAt).toDateString()
                    }}</span>

                    
                    <b-badge
                      :variant="
                        item.violationLvl == 'high'
                          ? 'danger'
                          : item.violationLvl == 'low'
                          ? 'warning'
                          : 'success'
                      "
                      class="m-2"
                      >{{ item.violationLvl }}</b-badge
                    >
                  </div>
                  <div @click="showModal(item)" >
                  <img  :src="getImageLanding(item.trxid)" alt="landing" class="img_custom_style" @error="$event.target.src=(`../no_image.png`);">
                  </div>
                  <!-- <div
                   
                    @click="showModal(item)"
                    :style="
                      'height:200px;width:180px; background:url(' +
                      getImageLanding(item.trxid) +'); background-size:cover; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px 10px 10px 10px'
                    "
                  ></div> -->
                  <b-badge
                    variant="warning"
                    style="font-size: 10px"
                    class="m-0"
                    >{{ item.country }}</b-badge
                  >
                </div>
              </b-card>
            </b-row>
          </px-card >
            </b-col>

          <b-col lg="12" class="mt-2">
          <px-card title="Service Test">
            <b-row class="p-3" style="max-height: 60vh;overflow-y: scroll;">
              <b-card
                style="
                  border-radius: 10px;
                  max-width: 15rem;
                  border: 1px dashed #1d75bc;
                "
                v-for="item in insightsList"
                :key="item._id"
                type="gradient-red"
                class="ml-3 mt-1"
              >
                <div>
                  <div style="min-height: 80px;max-height: 80px;overflow: auto;">
                    <h4 class="text-blue">{{ item.spdocs.name +"-" +item.sdocs.title }}</h4>
                  </div>
                  <div>
                    <span style="font-size: 10px" class="m-0 text-blue">{{
                      new Date(item.createdAt).toDateString()
                    }}</span>

                    <b-badge
                      :variant="
                        item.violationLvl == 'high'
                          ? 'danger'
                          : item.violationLvl == 'low'
                          ? 'warning'
                          : 'success'
                      "
                      class="m-2"
                      >{{ item.violationLvl }}</b-badge
                    >
                  </div>
                  <div @click="showModal(item)" >
                  <img  :src="getImageLanding(item.trxid)" alt="landing" class="img_custom_style" @error="$event.target.src=(`../no_image.png`);">
                  </div>
                  <!-- <div
                    @click="showModal(item)"
                    :style="
                      'height:200px;width:180px; background:url(' +
                      getImageLanding(item.trxid) +'); background-size:cover; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px 10px 10px 10px'
                    "
                  >
                </div> -->
                  <b-badge
                    variant="warning"
                    style="font-size: 10px"
                    class="m-0"
                    >{{ item.country }}</b-badge
                  >
                </div>
              </b-card>
            </b-row>
          </px-card>
        </b-col>

        <b-col lg="12" class="mt-5">
          <px-card title="APK Downloads">
            <b-row class="p-3" style="max-height: 60vh;overflow-y: scroll;">
              <b-card
                style="border-radius: 10px;max-width: 15rem;border: 1px dashed #1d75bc;"
                v-for="item in apkList"
                :key="item._id"
                type="gradient-red"
                class="ml-3 mt-1"
              >
                <div>
                  <div style="min-height: 80px;max-height: 80px;overflow: auto;">
                    <h4 class="text-blue">{{ item.spdocs.name +"-" +item.sdocs.title }}</h4>
                  </div>
                   <b-badge variant="dark">{{adTypeArray.find(e=> e.id == item.sdocs.adtype).name }}</b-badge>
                  <div>
                    <span style="font-size: 10px" class="m-0 text-blue">{{
                      new Date(item.createdAt).toDateString()
                    }}</span>

                    <b-badge
                      :variant="
                        item.violationLvl == 'high'
                          ? 'danger'
                          : item.violationLvl == 'low'
                          ? 'warning'
                          : 'success'
                      "
                      class="m-2"
                      >{{ item.violationLvl }}</b-badge
                    >
                  </div>
                  <div @click="showModal(item)" >
                  <img  :src="getImageLanding(item.trxid)" alt="landing" class="img_custom_style"  @error="$event.target.src=(`../no_image.png`);">
                  </div>
                  <!-- <div
                    @click="showModal(item)"
                    :style="
                      'height:200px;width:180px; background:url('+
                      getImageLanding(item.trxid) +'); background-size:cover; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px 10px 10px 10px'
                    "
                  ></div> -->
                  <b-badge
                    variant="warning"
                    style="font-size: 10px"
                    class="m-0"
                    >{{ item.country }}</b-badge
                  >
                </div>
              </b-card>
            </b-row>
          </px-card>
        </b-col>
        </b-row>
        
    </div>
</div>
</template>




<script>

import ContentPage from './Dashboard/Content.vue';
import dashbordService from "../../services/DashboardService";
import globalConfig from '../../services/globalLit';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
export default{
    name : 'Dashboard',
    components : {
        ContentPage,
        DateRangePicker
    },
    data() {
    return {
      dateRange:{
        startDate: new Date(), 
        endDate: new Date().setMonth(new Date().getMonth() - 2)
      },
      customDateRanges: {
        'Today': [new Date(), new Date()],
        'Yesterday': [
          new Date(new Date().setDate(new Date().getDate() - 1)),
          new Date(new Date().setDate(new Date().getDate() - 1))
        ],
        'This Month': [
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ],
        'This Year': [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31)
        ],
        'Last Month': [
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        ]
      },
      adTypeArray: globalConfig.adTypeArray,
      apkList: [],
      insightsList: [],
      bannerList: [],
      thumbnailUri:
        process.env.VUE_APP_BACKHOST +
        ":" +
        process.env.VUE_APP_BACKPORT +
        "/videos/sr_",
      videoUrl: "",
      userActivateStatus: false,
      addetail: {},
      filter: {},
      linkschain: [],
      allLinks: [],
      user: "",
      user_status: false,
      fields: [
        {
          key: "link",
          label: "Links",
          sortable: true,
          editable: true,
          tbClass: "bTableThStyle",
        },
        {
          key: "copy",
          label: "action",
          tbClass: "acClass",
        },
      ],
    };
  },
  mounted() {
    // this.getLinksData();
    this.userActivateStatus = window.sessionStorage.getItem("status");
     
     this.init();
  },
  methods: {
    async filterData(){
  
      this.filter.sdate = this.dateRange.startDate;
      this.filter.edate = this.dateRange.endDate;
      this.$root.$emit('date-range-updated', this.dateRange)
      this.init('dateRange');
    },
    decodeString(str) {
      var div = document.createElement("textarea");
      div.innerHTML = str;
      var decoded = div.firstChild.nodeValue;

      return decoded;
    },
    maxWidth() {
      return "text-truncate";
    },
   
    showModal(item) {
        let payload = {
        item: {trxid: item.trxid}
      }
    
    console.log("e")
      // this.$root.$emit("openTrx", Object.assign({}, payload));
      // this.$bvModal.show("openServiceInfo");
      this.$root.$emit("showtrxforlist",item);
      this.$bvModal.show("openSerList");
    },
    async getChains(id) {
      let data = await dashbordService.chains(id);
      this.linkschain = data.data.map((e) => {
        return { link: e };
      });
    },
    copyText(link) {
      navigator.clipboard.writeText(link);
      this.$notify({
        type: "info",
        message: "Copied to clipboard",
      });
    },
    async init(txt) {
        let apkListPayload = {}
        let insightsListPayload = {}
        let bannerListPayload = {}

        if(txt){
          apkListPayload.sdate = this.dateRange.startDate
          apkListPayload.edate = this.dateRange.endDate
          insightsListPayload.sdate = this.dateRange.startDate
          insightsListPayload.edate = this.dateRange.endDate
          bannerListPayload.sdate = this.dateRange.startDate
          bannerListPayload.edate = this.dateRange.endDate
        }
        let apklisApiResponse = await dashbordService.apkList(apkListPayload)
        let insightsListResponse = await dashbordService.insightsList(insightsListPayload)
        let bannerListResponse = await dashbordService.bannerList(bannerListPayload)
        
        if(insightsListResponse.result) {
          this.insightsList = insightsListResponse.data
        }
        if(apklisApiResponse.result) {
            this.apkList = apklisApiResponse.data
        }  
        if(bannerListResponse.result) {
            this.bannerList = bannerListResponse.data
        }  
        console.log(this.bannerList)
    },
    async getLinksData() {
      try {
        this.allLinks = await dashbordService.lists();
        console.log("all data", this.allLinks);
      } catch (e) {
        console.error("ERROR: getting data");
        console.error(e);
      }
    },
    getImageLanding(id){
      return globalConfig.getLandingImages+`/${id}`
    },
    ChangedDate(){
      let sdate = new Date(
        this.dateRange.startDate.getTime() - this.dateRange.startDate.getTimezoneOffset() * 60000
      ).toISOString();

      let edate = new Date(
        this.dateRange.endDate.getTime() - this.dateRange.endDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.dateRange.startDate = sdate
      this.dateRange.endDate = edate
      // this.filterData(this.monitor_id, this.dateRange)
      
    },
    changeDateFormate(d){
        return moment.utc(d).format('D/M/YYYY')
    },
  }
}
</script>


<style scoped>
.img_custom_style{
  min-height:200px;min-width:180px;max-height: 200px;max-width: 180px;border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.img_custom_style:hover{
  cursor: pointer;
  border: 2px solid blue;
}
</style>